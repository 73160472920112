export const environment = {
  production: false,
  buildNo:202504021218,
  ver:'TEST 2.0.2',
  baseUrl: 'https://test.admin.oov2.mojosoft.work/ws/',
  wsPhp: 'https://test.admin.oov2.mojosoft.work/ws-ext/',
  apiReportUrl: 'https://test.admin.oov2.mojosoft.work/rpt-ws/',
  uplodFilePath: 'https://test.admin.oov2.mojosoft.work/resources',
  itemImageWidth: 1000,
  itemImageHeight: 1000,
  userId: 'oos',
  password: 'stpierresOOS123#@!test',
  appKey: "a383451512bd77ef1b45fd220341ac2c",
  crm:{
    apiUrl:'https://test.crm.mojosoft.work/ws',
    userId: 'oos',
    password: 'stpierresOOS123#@!test',
    appKey: "a383451512bd77ef1b45fd220341ac2c",
  }
};
